import React from "react";
import { Provider } from "react-redux";
import AppContainer from "./src/components/AppContainer";

import "./src/styles/custom.css";

import "./src/components/ImageCreator/style.scss";
import "./src/components/ImagePreview/style.scss";
import "./src/components/TextSubmitterForm/style.scss";
import "./src/components/Modal/style.scss";
import "./src/components/Editor/style.scss";
import "./src/components/Header/style.scss";
import "./src/components/Share/style.scss";
import "./src/components/GenerationCount/style.scss";
import "./src/components/TodaysContent/style.scss";
import "./src/components/AppContainer/style.scss";
import "./src/components/Loader/style.scss";
import "./src/components/Blogs/style.scss";
import "./src/components/ChromeExtension/style.scss";

import "./src/styles/style.scss";

import store from "./src/store";

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <AppContainer>{element}</AppContainer>
    </Provider>
  );
};
